<template>
	<v-container>
		<h3 class="text-capitalize">
			<v-chip label small color="green" dark>{{ api.usersConnected.length }}</v-chip>
			{{ "live users" | trans }}
		</h3>
		<v-card outlined class="my-2">
			<v-list class="ma-0 pa-0" dense two-line>
				<template v-for="user in api.usersConnected">
					<v-list-item :key="user.id" @click="getIpInfo(user)">
						<v-list-item-avatar rounded>
							<v-img :src="user.imagen"></v-img>
						</v-list-item-avatar>
						<v-list-item-content>
							<v-list-item-title>{{ user.name }}</v-list-item-title>
							<v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
							<template>
								<v-list-item-subtitle>{{ user.cargo }} - {{ user.departamento }}</v-list-item-subtitle>
							</template>
						</v-list-item-content>
						<v-list-item-avatar
							rounded
							size="24"
							:title="`${user.ipInfo.region} ${user.ipInfo.country}`"
							v-if="user.ipInfo"
						>
							<v-img
								:src="`https://cdn.staticaly.com/gh/hjnilsson/country-flags/master/svg/${user.ipInfo.country.toLowerCase()}.svg`"
							></v-img>
						</v-list-item-avatar>
						<v-list-item-action>
							<v-btn
								text
								class="mx-2"
								x-small
								link
								color="grey darken-4"
								target="_blank"
								:href="route(user.route.path)"
								:title="route(user.route.path)"
								v-if="user.route"
							>
								<v-icon left class="primary--text">mdi-open-in-new</v-icon>
								{{ user.route.title }} -
								{{ user.route.name | trans }}
							</v-btn>
						</v-list-item-action>
						<v-list-item-action class="hidden-md-and-down">
							<v-list-item-subtitle class="caption">{{ user.ip }}</v-list-item-subtitle>
							<v-list-item-subtitle>
								<v-icon v-if="user.userAgent" :title="`${user.userAgent.browser.name} ${user.userAgent.browser.major}`">
									{{ icons[user.userAgent.browser.name] || "mdi-open-in-app" }}</v-icon
								>
								<v-icon v-if="user.userAgent" :title="`${user.userAgent.os.name} ${user.userAgent.os.version}`">
									{{ icons[user.userAgent.os.name] || "mdi-laptop" }}</v-icon
								>
							</v-list-item-subtitle>
						</v-list-item-action>
						<v-list-item-action class="body-2" :title="'duration' | trans">
							{{ now | moment("diff", user.timestamp, "seconds") | dur }}
						</v-list-item-action>
					</v-list-item>
					<v-divider :key="user.id + '-divider'"></v-divider>
				</template>
			</v-list>
		</v-card>
	</v-container>
</template>
<script>
var interval;
import axios from "axios";
import UAParser from "ua-parser-js";
export default {
	mounted() {
		this.now = new Date();
		interval = setInterval(() => {
			this.now = new Date();
		}, 1000);
	},
	methods: {
		route(path) {
			if (!path) return;
			let endpoint = process.env.VUE_APP_ENDPOINT || "web";
			return `${this.api.url.replace("public/", `${endpoint}/`)}#${path}`;
		},
		getIpInfo(user) {
			if (!user.ipInfo) {
				if (window.localStorage.getItem("ip-info-" + user.ip)) {
					user.ipInfo = JSON.parse(window.localStorage.getItem("ip-info-" + user.ip));
				} else {
					axios.get(`https://ipinfo.io/${user.ip}/json?token=0f94d6404434bb`).then((resp) => {
						window.localStorage.setItem("ip-info-" + user.ip, JSON.stringify(resp.data));
						user.ipInfo = resp.data;
					});
				}
			}
		},
	},
	beforeDestroy() {
		clearInterval(interval);
	},
	data() {
		return {
			now: new Date(),
			icons: {
				"Mac OS": "mdi-apple",
				"Mac OS X": "mdi-apple",
				Chrome: "mdi-google-chrome",
				Linux: "mdi-linux",
				Firefox: "mdi-firefox",
				Safari: "mdi-apple-safari",
				Ubuntu: "mdi-ubuntu",
				Debian: "mdi-debian",
				Windows: "mdi-microsoft-windows",
			},
		};
	},
	watch: {
		"api.usersConnected": {
			immediate: true,
			handler() {
				this.api.usersConnected.forEach((user) => {
					if (!user.userAgent) {
						var parser = new UAParser();
						user.userAgent = parser.setUA(user.user_agent).getResult();
					}
					if (!user.ipInfo) {
						if (window.localStorage.getItem("ip-info-" + user.ip)) {
							user.ipInfo = JSON.parse(window.localStorage.getItem("ip-info-" + user.ip));
						} else {
							axios.get(`https://ipinfo.io/${user.ip}/json?token=0f94d6404434bb`).then((resp) => {
								window.localStorage.setItem("ip-info-" + user.ip, JSON.stringify(resp.data));
								user.ipInfo = resp.data;
							});
						}
					}
				});
			},
		},
	},
};
</script>
